<template>
    <v-container>
        <validation-observer 
            v-slot="{ invalid }"
            ref="observer"
        >
            <v-form>
                <v-row>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <v-card-title>
                            <span 
                                v-if="!edit"
                                class="text-h6"
                            >
                                Add Item
                            </span>
                            <span 
                                v-if="edit"
                                class="text-h6"
                            >
                                Edit Item
                            </span>
                            <v-spacer></v-spacer>
                            <v-btn
                                icon
                            >
                                <v-icon></v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-row>
                            <v-col 
                                cols="12" 
                                class="mb-0 pb-0"
                            >
                                <validation-provider 
                                    v-slot="{ errors }"
                                    name="Item name"
                                    rules="required|max:100" 
                                >
                                    <v-text-field 
                                        v-model="name" 
                                        label="Item Name" 
                                        class="ml-4 mr-4"
                                        :error-messages="errors"
                                        outlined 
                                        required
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12" 
                                class="mt-0 mb-0 pt-0 pb-0"
                            >
                                <validation-provider 
                                    v-slot="{ errors }"
                                    name="Description"
                                    rules="max:1000" 
                                >
                                    <v-textarea 
                                        v-model="description" 
                                        label="Description" 
                                        class="ml-4 mr-4"
                                        rows="3"
                                        :error-messages="errors"
                                        outlined 
                                    ></v-textarea>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12" xl="6" lg="6" md="6" sm="6" 
                                class="mt-0 mb-0 pt-0 pb-0"
                            >
                                <validation-provider 
                                    v-slot="{ errors }"
                                    name="Weight"
                                    rules="double:1,dot|max:100" 
                                >
                                    <v-text-field 
                                        v-model.number="weight" 
                                        label="Weight" 
                                        class="ml-4 mr-4"
                                        suffix="pounds"
                                        :error-messages="errors"
                                        outlined                           
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col 
                                cols="0" xl="6" lg="6" md="6" sm="6" 
                                class="mt-0 mb-0 pt-0 pb-0"
                            ></v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="6" 
                                class="mt-0 mb-0 pt-0 pb-0"
                            >
                                <validation-provider 
                                    v-slot="{ errors }"
                                    name="Value amount"
                                    rules="numeric|max:100" 
                                >
                                    <v-text-field
                                        v-model.number="valueAmount" 
                                        label="Value"
                                        class="ml-4"
                                        :error-messages="errors"
                                        outlined
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col 
                                cols="6" 
                                class="mt-0 mb-0 pt-0 pb-0"
                            >
                                <v-select
                                    v-model="valueCurrency" 
                                    :items="currencies"
                                    label="Currency"
                                    class="mr-4"
                                    outlined
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="12" 
                                class="mt-0 mb-0 pt-0 pb-0"
                            >
                                <v-checkbox
                                    v-model="buyItem" 
                                    class="ml-4 mt-0 pt-0"
                                >
                                    <template v-slot:label>
                                        <span v-if="!edit">
                                            Purchase Item with Party Coins
                                        </span>
                                        <span v-if="edit">
                                            Purchase Additional Items with Party Coins
                                        </span>
                                        <v-tooltip right>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="ml-4"
                                                >
                                                    mdi-help-circle
                                                </v-icon>
                                            </template>
                                            <span class="text-subtitle-1">
                                                If this box is checked, the amount below will be deducted from the party coins.
                                            </span>
                                        </v-tooltip>
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row v-if="buyItem">
                            <v-col 
                                cols="6" 
                                class="mt-0 mb-0 pt-0 pb-0"
                            >
                                <validation-provider 
                                    v-slot="{ errors }"
                                    name="Cost amount"
                                    rules="numeric|max:100" 
                                >
                                    <v-text-field
                                        v-model.number="costAmount" 
                                        label="Total Cost"
                                        class="ml-4"
                                        :error-messages="errors"
                                        outlined
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col 
                                cols="6" 
                                class="mt-0 mb-0 pt-0 pb-0"
                            >
                                <v-select
                                    v-model="costCurrency" 
                                    :items="currencies"
                                    label="Currency"
                                    class="mr-4"
                                    outlined
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <v-card-title>
                            <span class="text-h6">
                                Item Owners
                            </span>
                            <v-spacer></v-spacer>
                            <v-btn
                                v-on:click="$emit('closeDialog')"
                                color="red"
                                outlined
                                icon
                            >
                                <v-icon>
                                        mdi-close
                                </v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-row 
                            v-for="(i, index) in individuals" 
                            :key="i.id"
                        >
                            <v-col 
                                cols="4.5" 
                                class="mb-0 pb-0"
                            >
                                <v-select
                                    v-model="i.owner" 
                                    :items="memberOptions"
                                    label="Owner"
                                    class="ml-4"
                                    outlined
                                ></v-select>
                            </v-col>
                            <v-col cols="4.5" class="mb-0 pb-0">
                                <validation-provider 
                                    v-slot="{ errors }"
                                    name="Quantity"
                                    rules="numeric|max:100" 
                                >
                                    <v-text-field
                                        v-model.number="i.quantity" 
                                        label="Quantity"
                                        :error-messages="errors"
                                        outlined
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col 
                                cols="3" 
                                class="mb-0 pb-0"
                            >
                                <v-btn 
                                    v-on:click="individuals.splice(index, 1)"
                                    v-if="individuals.length > 1"
                                    class="mt-1"
                                    color="red"
                                    aria-label="delete owner"
                                    outlined
                                    large
                                    icon
                                >
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col 
                                cols="4" 
                                class="mt-0 mb-0 pt-0 pb-0 ml-4"
                            >
                                <v-btn 
                                    v-on:click="individuals.push({'owner' : 'Party', 'quantity' : 1})"
                                    color="green"
                                    aria-label="add owner"
                                    outlined
                                    icon
                                >
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col 
                                cols="4" 
                                class="mt-0 mb-0 pt-0 pb-0"
                            ></v-col>
                        </v-row>
                        <v-card-title>
                            <span class="text-h6 mt-4">
                                Tags
                            </span>
                        </v-card-title>
                        <v-row>
                            <v-col 
                                cols="12" 
                                class="mb-0 pb-0"
                            >
                                <v-combobox
                                    v-model="tags"
                                    :items="allTags"
                                    label="Tags"
                                    class="ml-4 mr-4"
                                    chips 
                                    multiple  
                                    solo 
                                    flat
                                    outlined
                                >
                                    <template v-slot:selection="{ attrs, item, select, selected }">
                                        <v-chip
                                            v-bind="attrs"
                                            :input-value="selected"
                                            :color="getColor(item)"
                                            close
                                            dark
                                            @click="select"
                                            @click:close="tags.splice(tags.indexOf(item), 1)"
                                        >
                                            {{ item }}
                                        </v-chip>
                                    </template>
                                </v-combobox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-form>
            <v-btn 
                v-on:click="addItem"
                color="primary"
                class="ml-4 mr-4 mt-4 mb-4"
                :disabled="invalid"
                x-large
                depressed
            >
                Submit
            </v-btn>
            <v-btn 
                v-on:click="deleteLoot"
                v-if="edit"
                color="red"
                x-large
                dark
            >
                Delete
            </v-btn>
        </validation-observer>
    </v-container>
</template>

<script>
    import ColorHash from 'color-hash'
    import ProcessTransaction from '../mixins/ProcessTransaction'

    export default {
        name: 'AddItem',
        mixins: [ProcessTransaction],
        data() {
            return {
                id: '',
                name: '',
                description: '',
                valueAmount: 0,
                valueCurrency: 'gold',
                buyItem: false,
                costAmount: 0,
                costCurrency: 'gold',
                weight: 0,
                tags: [],
                individuals: [],
                createdAt: '',
                lastModifiedAt: '',
                currencies: [
                    { 'text' : 'Platinum', 'value' : 'platinum' },
                    { 'text' : 'Gold', 'value' : 'gold' },
                    { 'text' : 'Electrum', 'value' : 'electrum' },
                    { 'text' : 'Silver', 'value' : 'silver' },
                    { 'text' : 'Copper', 'value' : 'copper' },
                ],
                invalid: true,
                originalQuantity: 0
            };
        },
        props: {
            item: { type: Object },
        },
        watch: {
            // the cost amount defaults to the value amount times the total quantity of items (minus any previously existing items)
            individuals : {
                handler: function () {
                    this.costAmount = this.valueAmount * (this.totalQuantity - this.originalQuantity);
                },
                deep: true
            },

            // the cost amount defaults to the value amount times the total quantity of items (minus any previously existing items)
            valueAmount : function() {
                this.costAmount = this.valueAmount * (this.totalQuantity - this.originalQuantity);
            },

            // the cost currency defaults to the value currency
            valueCurrency : function() {
                this.costCurrency = this.valueCurrency;
            },
        },
        computed: {
            // true if editing the item, false if adding it
            edit: function() {
                return this.item != null;
            },

            // memberOptions include all party members plus an option for the party (i.e., unowned items)
            memberOptions: function() {
                return ['Party'].concat(this.$store.getters.partyMembers);
            },

            // tags belonging to all existing loot
            allTags: function() {
                return this.$store.getters.partyLootTags;
            },

            // party's coins
            partyCoins: function() {
                return this.$store.getters.partyCoins;
            },

            // total quantity of items
            totalQuantity: function() {
                return this.individuals.reduce((quantity, i) => { return quantity + i.quantity }, 0)
            }
        },
        methods: {
            // add or edit a loot item
            addItem() {
                // remove any empty owner rows and consolidate any duplicates
                for (var i1 = 0; i1 < this.individuals.length; i1++) {
                    if (this.individuals[i1].owner === '' || this.individuals[i1].quantity === 0) {
                        this.individuals.splice(i1, 1);
                    } else {
                        for (var i2 = 0; i2 < this.individuals.length; i2++) {
                            if (this.individuals[i1].owner === this.individuals[i2].owner && i1 !== i2) {
                                this.individuals[i1].quantity += this.individuals[i2].quantity;
                                this.individuals.splice(i2, 1);
                            }
                        }
                    }
                }

                // create new / updated item 
                const item = {
                    'id' : this.id,
                    'name' : this.name,
                    'description': this.description.trim(),
                    'valueAmount' : this.valueAmount,
                    'valueCurrency' : this.valueCurrency,
                    'weight' : this.weight,
                    'individuals' : this.individuals, 
                    'tags' : this.tags
                }

                // if the cost of the item is spent, attempt to purchase it
                let canAddItem = true;
                if (this.buyItem) {
                    canAddItem = this.purchaseItem(item);
                }

                // check if the item can be purchased
                // if not, notify the parent of insufficient funds
                if (canAddItem) {
                    // push the item details to state
                    // which will update the database
                    // notify the parent on success or failure
                    if (this.edit) {
                        item['createdAt'] = new Date(this.createdAt).getTime();
                        item['lastModifiedAt'] = Date.now();

                        this.$store.dispatch('editLoot', item)
                        .then(() => { 
                            this.$emit('lootUpdated')
                        }, () => {
                            this.$emit('lootUpdateFailed')
                        });
                    } else {
                        item['createdAt'] = Date.now();
                        item['lastModifiedAt'] = Date.now();

                        this.$store.dispatch('addLoot', item)
                        .then(() => { 
                            this.$emit('lootUpdated')
                        }, () => {
                            this.$emit('lootUpdateFailed')
                        });
                    }
                } else {
                    this.$emit('insufficientFunds')
                }            
            },

            // attempt to purchase an item
            purchaseItem() {
                // process the transaction
                const totalCost = this.costAmount
                const newCoins = Object.assign({}, this.partyCoins);
                const transactionDetails = this.processTransaction('expense', totalCost, this.costCurrency, newCoins)
                
                // check if the spend succeeded
                if (!transactionDetails.insufficientFunds) {
                    // create a transaction object
                    const transaction = {
                        'transactionType' : 'expense',
                        'valueAmount' : totalCost,
                        'valueCurrency' : this.costCurrency,
                        'reason' : 'Purchased ' + (this.totalQuantity - this.originalQuantity).toString() + ' ' + this.name,
                        'coins' : transactionDetails.coins,
                        'time' : Date.now()
                    }

                    // push the transaction details to state
                    // which will update update the party coins and log
                    this.$store.dispatch('addTransaction', transaction)
                    return true;
                } else {
                    return false
                }
            },

            // delete a loot item
            deleteLoot() {
                // push the item details to state
                // which will update the database
                // notify parent on a success or a fail
                this.$store.dispatch('deleteLoot', this.id)
                .then(() => { 
                    this.$emit('lootUpdated')
                }, () => {
                    this.$emit('lootUpdateFailed')
                });
            },

            // get color of tag
            getColor(text) {
                const colorHash = new ColorHash();
                return colorHash.hex(text);
            }
        },
        created() {
            // set item values
            // if editing, these belong to the item passed from the parent
            // otherwise, just set the first owner to the member
            if (this.edit) {
                this.id = this.item.id;
                this.name = this.item.name;
                this.description = this.item.description;
                this.valueAmount = this.item.valueAmount;
                this.valueCurrency = this.item.valueCurrency;
                this.weight = this.item.weight
                this.tags = this.item.tags;
                this.individuals = this.item.individuals;
                this.createdAt = this.item.createdAt;
                this.lastModifiedAt = this.item.lastModifiedAt;
                this.originalQuantity = this.totalQuantity
            } else {
                this.individuals.push({ 
                    'owner' : 'Party', 
                    'quantity' : 1 
                });
            }
        },
    }
</script>
