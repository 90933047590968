export default {
    methods: {
      // process an income or expense
      processTransaction(transactionType, valueAmount, valueCurrency, coins) {
        // initialize boolean for whether funds are insufficient
        let insufficientFunds = false;

        // incomes
        if (transactionType === 'income') {
          // add the value amount to the appropriate currency
          coins[valueCurrency] += valueAmount;
        } 

        // expenses
        if (transactionType === 'expense') {
          // check if the value amount can simply be subtracted from the appropriate currency
          // if so, do this
          if (coins[valueCurrency] >= valueAmount) {
            coins[valueCurrency] -= valueAmount
          } 
          
          // otherwise, go through each currency, subtracting the appropriate amount
          else {
            // convert the value amount to copper
            let valueAmountCopper = 0
            if (valueCurrency == 'platinum') { valueAmountCopper = valueAmount * 1000 }
            if (valueCurrency == 'gold') { valueAmountCopper = valueAmount * 100 }
            if (valueCurrency == 'electrum') { valueAmountCopper = valueAmount * 50 }
            if (valueCurrency == 'silver') { valueAmountCopper = valueAmount * 10 }
            if (valueCurrency == 'copper') { valueAmountCopper = valueAmount }

            // initialize the remaining amount to spend and the change
            let amountLeftCopper = valueAmountCopper;
            let changeCopper = 0;

            // check platinum
            if ((coins.platinum * 1000) >= amountLeftCopper) {
              // if platinum will cover the expense
              // subtract the appropriate amount of platinum (just large enough to cover the expense)
              const coinsSpent = Math.ceil(amountLeftCopper / 1000);
              coins.platinum -= coinsSpent;

              // calculate the change in copper
              changeCopper = (coinsSpent * 1000) - amountLeftCopper; 
            } else {
              // otherwise
              // subtract all the platinum from the amount left
              amountLeftCopper -= (coins.platinum * 1000);
              coins.platinum = 0;

              // then check gold
              if ((coins.gold * 100) >= amountLeftCopper) {
                // if gold will cover the expense
                // subtract the appropriate amount of gold (just large enough to cover the expense)
                const coinsSpent = Math.ceil(amountLeftCopper / 100);
                coins.gold -= coinsSpent;

                // calculate the change in copper
                changeCopper = (coinsSpent * 100) - amountLeftCopper; 
              } else {
                // otherwise
                // subtract all the gold from the amount left
                amountLeftCopper -= (coins.gold * 100);
                coins.gold = 0;

                // then check electrum
                if ((coins.electrum * 50) >= amountLeftCopper) {
                  // if electrum will cover the expense
                  // subtract the appropriate amount of electrum (just large enough to cover the expense)
                  const coinsSpent = Math.ceil(amountLeftCopper / 50);
                  coins.electrum -= coinsSpent;
  
                  // calculate the change in copper
                  changeCopper = (coinsSpent * 50) - amountLeftCopper; 
                } else {
                  // otherwise
                  // subtract all the electrum from the amount left
                  amountLeftCopper -= (coins.electrum * 50);
                  coins.electrum = 0;

                  // then check silver
                  if ((coins.silver * 10) >= amountLeftCopper) {
                    // if silver will cover the expense
                    // subtract the appropriate amount of silver (just large enough to cover the expense)
                    const coinsSpent = Math.ceil(amountLeftCopper / 10);
                    coins.silver -= coinsSpent;
    
                    // calculate the change in copper
                    changeCopper = (coinsSpent * 10) - amountLeftCopper; 
                  } else {
                    // otherwise
                    // subtract all the silver from the amount left
                    amountLeftCopper -= (coins.silver * 10);
                    coins.silver = 0;
                    
                    // then check copper
                    if (coins.copper >= amountLeftCopper) {
                      const coinsSpent = amountLeftCopper;
                      coins.copper -= coinsSpent;
                    } else {
                      // if there is not enough money, set insufficient funds to true
                      insufficientFunds = true;
                    }
                  }
                }
              }
            }

            // convert the copper change to gold, silver, and copper
            if (changeCopper > 0) {
              const optimizedChangeGold = Math.floor(changeCopper / 100);
              const optimizedChangeSilver = Math.floor((changeCopper - (optimizedChangeGold * 100)) / 10);
              const optimizedChangeCopper = changeCopper - (optimizedChangeGold * 100) - (optimizedChangeSilver * 10);

              // add the change to the coins
              coins.gold += optimizedChangeGold;
              coins.silver += optimizedChangeSilver;
              coins.copper += optimizedChangeCopper;
            }
          }
        }

        // return results
        const payload = {
          'insufficientFunds' : insufficientFunds,
          'coins' : coins
        }
        return payload
      }
    }
  };