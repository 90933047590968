<template>
    <v-container class="full-width-container">
        <v-row class="text-center">
            <v-col cols="12">
                <v-card flat>
                    <v-data-table
                        :headers="coinHeaders"
                        :items="[partyCoins]"
                        dense
                        hide-default-footer
                        disable-sort
                    >
                        <template v-slot:item.platinum="{ item }">
                            <v-edit-dialog
                                @save="saveCoins('platinum')"
                                @open="editedCoins.platinum = item.platinum"
                            >
                                {{ item.platinum }}
                                <template v-slot:input>
                                    <v-text-field
                                        v-model="editedCoins.platinum"
                                        label="Platinum"
                                        type="number"
                                        single-line
                                    >
                                    </v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.gold="{ item }">
                            <v-edit-dialog
                                @save="saveCoins('gold')"
                                @open="editedCoins.gold = item.gold"
                            >
                                {{ item.gold }}
                                <template v-slot:input>
                                    <v-text-field
                                        v-model="editedCoins.gold"
                                        label="Gold"
                                        type="number"
                                        single-line
                                    >
                                    </v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.electrum="{ item }">
                            <v-edit-dialog
                                @save="saveCoins('electrum')"
                                @open="editedCoins.electrum = item.electrum"
                            >
                                {{ item.electrum }}
                                <template v-slot:input>
                                    <v-text-field
                                        v-model="editedCoins.electrum"
                                        label="Electrum"
                                        type="number"
                                        single-line
                                    >
                                    </v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.silver="{ item }">
                            <v-edit-dialog
                                @save="saveCoins('silver')"
                                @open="editedCoins.silver = item.silver"
                            >
                                {{ item.silver }}
                                <template v-slot:input>
                                    <v-text-field
                                        v-model="editedCoins.silver"
                                        label="Silver"
                                        type="number"
                                        single-line
                                    >
                                    </v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.copper="{ item }">
                            <v-edit-dialog
                                @save="saveCoins('copper')"
                                @open="editedCoins.copper = item.copper"
                            >
                                {{ item.copper }}
                                <template v-slot:input>
                                    <v-text-field
                                        v-model="editedCoins.copper"
                                        label="Copper"
                                        type="number"
                                        single-line
                                    >
                                    </v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                    </v-data-table>
                    <v-card-text class="text-left grey--text text--darken-2">
                        <span class="text-subtitle-1">
                            Total Weight: {{ coinWeight }} pounds
                        </span>
                        <br>
                        <span class="text-subtitle-1">
                            Total Value: {{ coinValue }}
                        </span>
                        <br>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn 
                            v-on:click="transactionDialog=!transactionDialog"
                            class="mb-0 ml-2" 
                            color="secondary"
                            depressed
                        >
                            Add Transaction
                            <v-icon class="ml-2">
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-divider class="mt-4 ml-4 mr-4"></v-divider>
                        <v-card-title class="pb-0">
                            <span class="text-h6 mt-0 mb-0 pb-0">
                                Quick Transactions
                            </span>
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-2 mr-2"
                                    >
                                        mdi-help-circle
                                    </v-icon>
                                </template>
                                <span class="text-subtitle-1">
                                    Quick Transactions allows you to define macros to make frequent transactions easier.
                                </span>
                            </v-tooltip>
                            <v-btn
                                v-on:click="editMacros=!editMacros"
                                color="primary"
                                aria-label="edit quick transactions"
                                icon
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-actions 
                            v-if="!editMacros"
                            class="ml-2"
                        >
                            <v-chip 
                                v-for="macro in partyMacros" 
                                :key="macro.id" 
                                v-text="macro.name" 
                                v-on:click="executeMacro(macro)"
                                :color="macro.transactionType==='income' ? 'green' : 'red'"
                                class="mt-0 mr-4 mb-2"
                                dark
                                label
                            ></v-chip>
                        </v-card-actions>
                        <v-row v-if="editMacros">
                            <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                                <v-list-item-group 
                                    class="mt-2"
                                >
                                    <template v-for="(macro, index) in partyMacros">
                                        <v-list-item :key="macro.id">
                                            <v-list-item-content class="text-left"> 
                                                <v-list-item-title v-text="macro.name" ></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-icon> 
                                                <v-btn 
                                                    v-on:click="updateMacro(macro)"
                                                    color="primary"
                                                    aria-label="edit macro"
                                                    outlined
                                                    icon
                                                >
                                                    <v-icon>
                                                        mdi-pencil
                                                    </v-icon>
                                                </v-btn>
                                            </v-list-item-icon>
                                            <v-list-item-icon>
                                                <v-btn 
                                                    v-on:click="deleteMacro(macro.id)"
                                                    color="red"
                                                    aria-label="delete macro"
                                                    outlined
                                                    icon
                                                >
                                                    <v-icon>
                                                        mdi-delete
                                                    </v-icon>
                                                </v-btn>
                                            </v-list-item-icon>
                                        </v-list-item>
                                        <v-divider 
                                            :key="index"
                                            class="ml-4"
                                        ></v-divider>
                                    </template>
                                </v-list-item-group>
                                <v-card-actions>
                                    <v-btn
                                        v-on:click="updateMacro()"
                                        class="mt-4 ml-1"
                                        color="green"
                                        aria-label="add macro"
                                        outlined
                                        icon
                                    >
                                        <v-icon>
                                            mdi-plus
                                        </v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-col>
                            <v-col cols="0" xl="6" lg="6" md="6" sm="6"></v-col>
                        </v-row>
                    <v-divider class="mt-4 ml-4 mr-4"></v-divider>
                    <v-card-title>
                        <span class="text-h6">
                            Expense Logs
                        </span>
                    </v-card-title>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        class="ml-4 mr-4"
                        outlined
                    ></v-text-field>
                    <v-data-table
                        :headers="logHeaders"
                        :items="partyLogs"
                        :search="search"
                        :custom-sort="customSort"
                        :sort-by.sync="column"
                        :sort-desc="sortDesc"
                    >
                        <template v-slot:item.undo="{ item }">
                            <v-icon
                                color="red"
                                v-on:click="undoTransaction=item; undoDialog=!undoDialog"
                            >
                                mdi-undo
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog 
            v-model="transactionDialog" 
            width="800"
            persistent
        >
            <v-card>
                <AddTransaction 
                    v-on:closeTransactionDialog="transactionDialog=false"
                    v-on:coinsUpdated="coinsUpdated" 
                    v-on:coinsUpdateFailed="coinsUpdateFailed"
                    v-on:insufficientFunds="insufficientFunds" 
                    ref="addTransaction"
                    :key="transactionKey"
                />
            </v-card>
        </v-dialog>
        <v-dialog 
            v-model="macroDialog" 
            width="800"
            persistent
        >
            <v-card>
                <AddMacro 
                    v-on:closeMacroDialog="macroDialog=false"
                    v-on:macroUpdated="macroUpdated"
                    v-on:macroUpdateFailed="macroUpdateFailed"
                    :macro="editMacro"
                    :key="macroKey"
                />
            </v-card>
        </v-dialog>
        <v-dialog 
            v-model="undoDialog" 
            width="800"
            persistent
        >
            <v-card>
                <v-card-title>
                    Confirm Undo Transaction
                </v-card-title>
                <v-card-text>
                    Are you sure you want to undo the following transaction?
                    <br><br>
                    <span class="ml-2">{{ undoTransaction.transaction }}<br></span>
                    <span v-if="undoTransaction.reason" class="ml-2">{{ undoTransaction.reason }}<br></span>
                    <span class="ml-2">{{ undoTransaction.time }}</span>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        v-on:click="reverseTransaction(undoTransaction)"
                        color="red"
                        aria-label="reverse transaction"
                        x-large
                        dark
                    >
                        Undo Transaction
                    </v-btn>
                    <v-btn
                        v-on:click="undoDialog=false; undoTransaction=''"
                        color="secondary"
                        aria-label="cancel reverse transaction"
                        x-large
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
                <br>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbarSuccess"
            timeout="5000"
            color="green"
            dark
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on:click="snackbarSuccess = false"
                    text
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar
            v-model="snackbarError"
            timeout="5000"
            color="red"
            dark
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on:click="snackbarError = false"
                    text
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
    import AddMacro from './AddMacro.vue';
    import AddTransaction from './AddTransaction.vue';
    import ProcessTransaction from '../mixins/ProcessTransaction'

    export default {
        name: 'CoinsList',
        components: {
            AddMacro,
            AddTransaction,
        },
        mixins: [ProcessTransaction],
        data() {
            return {
                search: "",
                editedCoins: {
                    'platinum' : 0,
                    'gold' : 0,
                    'electrum' : 0,
                    'silver' : 0,
                    'copper' : 0,
                },
                coinHeaders: [
                    { text: 'Platinum', value: 'platinum', filterable: false, sortable: false, width: '20%' },
                    { text: 'Gold', value: 'gold', filterable: false, sortable: false, width: '20%'  },
                    { text: 'Electrum', value: 'electrum', filterable: false, sortable: false, width: '20%'  },
                    { text: 'Silver', value: 'silver', filterable: false, sortable: false, width: '20%'  },
                    { text: 'Copper', value: 'copper', filterable: false, sortable: false, width: '20%'  },
                ],
                logHeaders: [
                    { text: 'Transaction', value: 'transaction' },
                    { text: 'Reason', value: 'reason' },
                    { text: 'Time', value: 'time' },
                    { text: 'Undo', value: 'undo' }
                ],
                column: 'time',
                sortDesc: true,
                editMacros: false,
                macroDialog: false,
                macroKey: 1,
                editMacro: null,
                transactionDialog: false,
                transactionKey: 1,
                undoDialog: false,
                undoTransaction: '',
                snackbarSuccess: false,
                snackbarError: false,
                snackbarMessage: '',
            };
        },
        computed: {
            // number of party members
            partyMembersCount: function() {
                return this.$store.getters.partyMembers.length;
            },

            // party macros
            partyMacros: function() {
                return this.$store.getters.partyMacros;
            },

            // party coins
            partyCoins: function() {
                return this.$store.getters.partyCoins;
            },

            // total coin weight
            coinWeight: function() {
                const coinQuantity = this.partyCoins.platinum + this.partyCoins.gold + 
                    this.partyCoins.electrum + this.partyCoins.silver + this.partyCoins.copper;
                
                return Math.floor(coinQuantity / 50) * 0.5;
            },

            // total coin value in gold, silver, and copper
            coinValue: function() {
                const totalCopperValue = (this.partyCoins.platinum * 1000) +  (this.partyCoins.gold * 100) + 
                    (this.partyCoins.electrum * 50) + (this.partyCoins.silver * 10) + (this.partyCoins.copper);

                const goldValue = Math.floor(totalCopperValue / 100);
                const silverValue = Math.floor((totalCopperValue - (goldValue * 100)) / 10)
                const copperValue = totalCopperValue - (goldValue * 100) - (silverValue * 10)
                
                return goldValue.toString() + " gold, " + silverValue.toString() + " silver, " + copperValue.toString() + " copper";
            },

            // party logs
            partyLogs: function() {
                const partyLogs = []
                this.$store.getters.partyLogs.forEach((l) => {
                    const log = {
                        transaction: '',
                        reason: l.reason,
                        time: l.time,
                        transactionType: l.transactionType,
                        valueAmount: l.valueAmount, 
                        valueCurrency: l.valueCurrency,
                        id: l.id
                    }

                    if (l.transactionType === 'income') {
                        log.transaction = 'Gained ' + l.valueAmount + ' ' + l.valueCurrency + ' coins.';
                    } else if (l.transactionType === 'expense') {
                        log.transaction = 'Spent ' + l.valueAmount + ' ' + l.valueCurrency + ' coins.';
                    }

                    partyLogs.push(log);
                })

                return partyLogs;
            }
        },
        methods: {
            // edit coins in line
            saveCoins(currency) {
                // validate the transaction by ensuring that the changed value is an integer
                const newCoins = Object.assign({}, this.partyCoins)
                if (Number.isInteger(parseInt(this.editedCoins[currency]))) {
                    // if so, update the coins array
                    newCoins[currency] = parseInt(this.editedCoins[currency]);

                    // create a transaction object
                    const transaction = {
                        'transactionType' : 'edit',
                        'valueAmount' : 0,
                        'valueCurrency' : '',
                        'reason' : '',
                        'coins' : newCoins,
                        'time' : Date.now()
                    }

                    // push details to state, which will update the database
                    // display a sucess or failure message on completion
                    this.$store.dispatch('addTransaction', transaction)
                    .then(() => { 
                        this.coinsUpdated();
                    }, () => {
                        this.coinsUpdateFailed();
                    });
                }
            },

            // execute a macro
            executeMacro(macro) {
                // process transaction using the macro details
                const newCoins = Object.assign({}, this.partyCoins);
                const transactionDetails = this.processTransaction(macro.transactionType, parseInt(macro.valueAmount), macro.valueCurrency, newCoins)
                
                // if there is sufficient money, push details to state, which will update the database, and notify parent
                // otherwise, notify parent of insufficient funds
                if (!transactionDetails.insufficientFunds) {

                    // create a transaction object
                    const transaction = {
                        'transactionType' : macro.transactionType,
                        'valueAmount' : macro.valueAmount,
                        'valueCurrency' : macro.valueCurrency,
                        'reason' : macro.description,
                        'coins' : transactionDetails.coins,
                        'time' : Date.now()
                    }

                    // push details to state, which will update the database
                    // display a sucess or failure message on completion
                    this.$store.dispatch('addTransaction', transaction)
                    .then(() => { 
                        this.coinsUpdated();
                    }, () => {
                        this.coinsUpdateFailed();
                    });
                } else {
                    this.insufficientFunds()
                }
            },

            // reverse a transaction from its log
            reverseTransaction(log) {
                // process transaction
                let transactionType = ''
                if (log.transactionType === 'income') transactionType = 'expense'
                else transactionType = 'income'

                const newCoins = Object.assign({}, this.partyCoins);
                const transactionDetails = this.processTransaction(transactionType, parseInt(log.valueAmount), log.valueCurrency, newCoins)

                // if there is sufficient money, push details to state, which will update the database, and notify parent
                // otherwise, notify parent of insufficient funds
                if (!transactionDetails.insufficientFunds) {

                    // create a transaction object
                    const transaction = {
                        'transactionType' : transactionType,
                        'valueAmount' : log.valueAmount,
                        'valueCurrency' : log.valueCurrency,
                        'reason' : '',
                        'coins' : transactionDetails.coins,
                        'time' : Date.now(),
                        'id': log.id
                    }

                    this.$store.dispatch('reverseTransaction', transaction)
                    .then(() => { 
                        this.coinsUpdated();
                    }, () => {
                        this.coinsUpdateFailed();
                    });
                } else {
                    this.insufficientFunds()
                }
            },

            // reset variables on transaction add or reverse
            coinsUpdated() {
                this.transactionDialog = false;
                this.undoDialog = false;
                this.transactionKey += 1;
                this.snackbarMessage = 'Transaction Successful';
                this.snackbarSuccess = true;
            },

            // reset variables on transaction fail
            coinsUpdateFailed() {
                this.snackbarMessage = 'Invalid Transaction Details; Transaction Failed.';
                this.snackbarError = true;
            },

            // reset variables on transaction fail
            insufficientFunds() {
                this.snackbarMessage = 'Insufficient Funds; Transaction Failed.';
                this.snackbarError = true;
            },

            // open the add / update macro dialog
            updateMacro(macro = null) {
                this.editMacro = macro;
                this.macroKey += 1;
                this.macroDialog = true;
            },

           // reset variables and show success message on macro update
            macroUpdated() {
                this.editMacros = false; 
                this.macroDialog = false; 
                this.editMacro = null; 
                this.macroKey += 1;
                this.snackbarMessage = 'Macro Updated Successfully.'
                this.snackbarSuccess = true;
            },

            // reset variables and show success message on macro update fail
            macroUpdateFailed() {
                this.editMacros = false; 
                this.macroDialog = false; 
                this.editMacro = null; 
                this.macroKey += 1
                this.snackbarMessage = 'Invalid Macro Details; Update Failed.'
                this.snackbarError = true;
            },

            // delete the macro
            async deleteMacro(macroId) {
                this.editMacros = false;
                this.$store.dispatch('deleteMacro', macroId)
                .then(() => { 
                    this.macroUpdate();
                }, () => {
                    this.macroUpdateFailed();
                });
            },

            // custom sort function for data table
            customSort(items, index, isDesc) {
                items.sort((a, b) => {
                    if (index[0] === 'time') {
                        if (!isDesc[0]) {
                            return this.compare(new Date(a.time).getTime(), new Date(b.time).getTime());
                        } else {
                            return this.compare(new Date(b.time).getTime(), new Date(a.time).getTime());
                        }
                    } else {
                        if (!isDesc[0]) {
                            return this.compare(a[index[0]], b[index[0]]);
                        } else {
                            return this.compare(b[index[0]], a[index[0]]);
                        }
                    }
                });
                return items;
            },

            // compare two numbers or strings
            compare(a, b) {
                if (a > b) { return 1; }
                else if (a < b) { return -1; }
                else if (a === b) { return 0; }
            },
        },
        created() {
            this.editedCoins.platinum = this.partyCoins.platinum;
            this.editedCoins.gold = this.partyCoins.gold;
            this.editedCoins.electrum = this.partyCoins.electrum;
            this.editedCoins.silver = this.partyCoins.silver;
            this.editedCoins.copper = this.partyCoins.copper;
        }
    }
</script>
