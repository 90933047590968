<template>
  <v-container class="partial-width-container">
    <v-card>
      <v-tabs v-model="tab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab>
          Loot Summary
        </v-tab>
        <v-tab>
          Coins Summary
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item :transition="false">
          <v-card>
            <v-card-text class="ml-3">
              <v-chip
                v-for="member in memberTabs"
                :key="member"
                v-on:click="changeMember(member)"
                v-text="member"
                class="mr-2 mt-2"
                :color="activeMember===member ? 'success' : 'light'"
                @click:close="activeMember=''"
                label
                close 
              >
              </v-chip>
            </v-card-text>
            <LootList :member="activeMember"/>
          </v-card>
        </v-tab-item>
        <v-tab-item :transition="false">
          <v-card>
            <CoinsList />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
  import CoinsList from '../components/CoinsList'
  import LootList from '../components/LootList'

  export default {
    name: 'Party',
    components: {
      CoinsList,
      LootList,
    },
    data() {
      return {
        tab: '',
        activeMember: 'Party',
      }
    },
    computed : {
      // memberTabs include all party members plus a view for the whole party
      memberTabs: function() {
        return ['Party'].concat(this.$store.getters.partyMembers);
      },
    },
    methods : {
      // change member to given member
      changeMember(member) {
        if (this.activeMember === member) {
          this.activeMember = '';
        } else {
          this.activeMember = member;
        }
      }
    }
  }
</script>
