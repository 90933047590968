<template>
    <v-container>
        <validation-observer 
            v-slot="{ invalid }"
            ref="observer"
        >
            <v-form>
                <v-card-title>
                    <span class="text-h6">
                        Add Transaction
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-on:click="$emit('closeTransactionDialog')"
                        color="red"
                        outlined
                        icon
                    >
                        <v-icon>
                                mdi-close
                        </v-icon>
                    </v-btn>
                </v-card-title>
                <v-row>
                    <v-col 
                        cols="12"
                        class="mt-0 pt-0 mb-0 pb-0"
                    >
                        <v-radio-group 
                            v-model="transactionType" 
                            label="Transaction Type"
                            class="mr-0 ml-4" 
                            mandatory
                        >
                            <v-radio
                                label="Income"
                                value="income"
                            ></v-radio>
                            <v-radio
                                label="Expense"
                                value="expense"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col 
                        cols="6"
                        class="mt-0 pt-0 mb-0 pb-0"
                    >
                        <validation-provider 
                            v-slot="{ errors }"
                            name="Value amount"
                            rules="required|numeric|max:100" 
                        >
                            <v-text-field
                                v-model.number="valueAmount" 
                                label="Value"
                                class="ml-4"
                                :error-messages="errors"
                                outlined
                                required
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col 
                        cols="6"
                        class="mt-0 pt-0 mb-0 pb-0"
                    >
                        <v-select
                            v-model="valueCurrency" 
                            :items="currencies"
                            label="Currency"
                            class="mr-4"
                            outlined
                            required
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col 
                        cols="12"
                        class="mt-0 pt-0 mb-0 pb-0"
                    >
                        <validation-provider 
                            v-slot="{ errors }"
                            name="Reason"
                            rules="max:1000" 
                        >
                            <v-textarea
                                v-model="reason"
                                label="Reason"
                                class="ml-4 mr-4 pb-0"
                                :error-messages="errors"
                                outlined
                            ></v-textarea>
                        </validation-provider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col 
                        cols="12"
                        class="mt-0 pt-0 mb-0 pb-0"
                    >
                        <v-btn 
                            v-on:click="addTransaction" 
                            class="mb-4 ml-4" 
                            color="primary"
                            :disabled="invalid"
                            x-large
                            depressed
                        >
                            Submit
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </validation-observer>
    </v-container>
</template>

<script>
    import ProcessTransaction from '../mixins/ProcessTransaction'

    export default {
        name: 'AddItem',
        mixins: [ProcessTransaction],
        data() {
            return {
                invalid: true,
                valueAmount: 0,
                valueCurrency: 'gold',
                transactionType: 'income',
                reason: '',
                currencies: [
                    { 'text' : 'Platinum', 'value' : 'platinum' },
                    { 'text' : 'Gold', 'value' : 'gold' },
                    { 'text' : 'Electrum', 'value' : 'electrum' },
                    { 'text' : 'Silver', 'value' : 'silver' },
                    { 'text' : 'Copper', 'value' : 'copper' },
                ]
            };
        },
        computed: {
            // party's coins
            partyCoins: function() {
                return this.$store.getters.partyCoins;
            },
        },
        methods: {
            // add a transaction
            addTransaction() {
                // process transaction
                const newCoins = Object.assign({}, this.partyCoins);
                const transactionDetails = this.processTransaction(this.transactionType, parseInt(this.valueAmount), this.valueCurrency, newCoins)

                // if there is sufficient money, push details to state, which will update the database, and notify parent
                // otherwise, notify parent of insufficient funds
                if (!transactionDetails.insufficientFunds) {
                    const transaction = {
                        'transactionType' : this.transactionType,
                        'valueAmount' : this.valueAmount,
                        'valueCurrency' : this.valueCurrency,
                        'reason' : this.reason,
                        'coins' : transactionDetails.coins,
                        'time' : Date.now()
                    }

                    // push details to state, which will update the database
                    // notify parent of success or failure
                    this.$store.dispatch('addTransaction', transaction)
                    .then(() => { 
                        this.$emit('coinsUpdated')
                    }, () => {
                        this.$emit('coinsUpdateFailed')
                    });
                } else {
                    this.$emit('insufficientFunds')
                }
            }
        },
    }
</script>
