<template>
    <v-container>
        <validation-observer 
            v-slot="{ invalid }"
            ref="observer"
        >
            <v-form>
                <v-card-title>
                    <span class="text-h6" v-if="!edit">
                        Add Macro
                    </span>
                    <span class="text-h6" v-if="edit">
                        Edit Macro
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-on:click="$emit('closeMacroDialog')"
                        color="red"
                        outlined
                        icon
                    >
                        <v-icon>
                                mdi-close
                        </v-icon>
                    </v-btn>
                </v-card-title>
                <v-row>
                    <v-col 
                        cols="12" 
                        class="mt-0 pt-0 mb-0 pb-0"
                    >
                        <v-radio-group 
                            v-model="transactionType" 
                            label="Transaction Type"
                            class="ml-4" 
                            mandatory
                        >
                            <v-radio
                                label="Income"
                                value="income"
                            ></v-radio>
                            <v-radio
                                label="Expense"
                                value="expense"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row>     
                    <v-col 
                        cols="6" 
                        class="mt-0 pt-0 mb-0 pb-0"
                    >
                        <validation-provider 
                            v-slot="{ errors }"
                            name="Value amount"
                            rules="required|numeric|max:100" 
                        >
                            <v-text-field
                                v-model.number="valueAmount" 
                                label="Value"
                                class="ml-4"
                                type="number"
                                :error-messages="errors"
                                outlined
                                required
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col cols="6" class="mt-0 pt-0 mb-0 pb-0">
                        <v-select
                            v-model="valueCurrency" 
                            :items="currencies"
                            label="Currency"
                            class="mr-4"
                            outlined
                            required
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col 
                        cols="12" 
                        class="mt-0 pt-0 mb-0 pb-0"
                    >
                        <validation-provider 
                            v-slot="{ errors }"
                            name="Macro name"
                            rules="required|max:100" 
                        >
                            <v-text-field
                                v-model="name" 
                                label="Macro Name"
                                class="ml-4 mr-4 mt-0 mb-0 pt-0 pb-0"
                                :error-messages="errors"
                                outlined
                                required
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col 
                        cols="12" 
                        class="mt-0 pt-0 mb-0 pb-0"
                    >
                        <validation-provider 
                            v-slot="{ errors }"
                            name="Macro description"
                            rules="max:1000" 
                        >
                            <v-textarea
                                v-model="description" 
                                label="Macro Description"
                                class="ml-4 mr-4"
                                :error-messages="errors"
                                outlined
                            ></v-textarea>
                        </validation-provider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col 
                        cols="12" 
                        class="mt-0 pt-0 mb-0 pb-0"
                    >
                        <v-btn 
                            v-on:click="addMacro" 
                            class="ml-4 mb-4" 
                            color="primary" 
                            :disabled="invalid"
                            x-large
                            depressed
                        >
                            Submit
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </validation-observer>
    </v-container>
</template>

<script>
    export default {
        name: 'AddMacro',
        data() {
            return {
                id: '',
                name: '',
                description: '',
                valueAmount: 0,
                valueCurrency: 'gold',
                transactionType: 'income',
                currencies: [
                    { 'text' : 'Platinum', 'value' : 'platinum' },
                    { 'text' : 'Gold', 'value' : 'gold' },
                    { 'text' : 'Electrum', 'value' : 'electrum' },
                    { 'text' : 'Silver', 'value' : 'silver' },
                    { 'text' : 'Copper', 'value' : 'copper' },
                ],
                invalid: true,
            };
        },
        props: {
            macro: { type: Object }
        },
        computed: {
            // true if editing the macro, false if adding it
            edit: function() {
                return this.macro != null;
            },
        },
        methods: {
            // add a macro
            addMacro() {
                // create new / updated macro 
                const macro = {
                    'id' : this.id,
                    'name' : this.name,
                    'description': this.description,
                    'transactionType': this.transactionType,
                    'valueAmount' : this.valueAmount,
                    'valueCurrency' : this.valueCurrency
                }

                // push the macro details to state
                // which will update the database
                // notify the parent on success or failure
                if (this.edit) {
                    this.$store.dispatch('editMacro', macro)
                    .then(() => { 
                        this.$emit('macroUpdated')
                    }, () => {
                        this.$emit('macroUpdateFailed')
                    });
                } else {
                    this.$store.dispatch('addMacro', macro)
                    .then(() => { 
                        this.$emit('macroUpdated')
                    }, () => {
                        this.$emit('macroUpdateFailed')
                    });
                }
            }
        },
        created() {
            // set item values
            // if editing, these belong to the macro passed from the parent
            if (this.macro != null) {
                this.id = this.macro.id;
                this.name = this.macro.name;
                this.description = this.macro.description;
                this.valueAmount = this.macro.valueAmount;
                this.valueCurrency = this.macro.valueCurrency;
                this.transactionType = this.macro.transactionType
            }
        }
    }
</script>
