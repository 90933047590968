<template>
    <v-container class="full-width-container">
        <v-row class="text-center">
            <v-col cols="12">
                <v-card flat>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                class="ml-4"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="filters"
                                :items="allTags"
                                label="Tags"
                                class="mr-4"
                                chips 
                                multiple 
                                solo
                                flat
                                outlined 
                            >
                                <template v-slot:selection="{ attrs, item, select, selected }">
                                    <v-chip
                                        v-bind="attrs"
                                        :input-value="selected"
                                        :color="getColor(item)"
                                        dark
                                        close
                                        @click="select"
                                        @click:close="filters.splice(filters.indexOf(item), 1)"
                                    >
                                        {{ item }}
                                    </v-chip>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-data-table
                        :headers="headers"
                        :items="loot"
                        :search="search"
                        :expanded.sync="expanded"
                        :custom-sort="customSort"
                        item-key="id"
                        show-expand 
                        single-expand
                    >
                        <template v-slot:item.name="{ item }">
                            <v-edit-dialog
                                :return-value.sync="item.name"
                                @open="editedItem.name=item.name"
                                @save="updateItem(item, 'name')"
                                @close="updateItem(item, 'name')"
                            >
                                {{ item.name }}
                                <template v-slot:input>
                                    <v-text-field
                                        v-model="editedItem.name"
                                        label="Name"
                                        single-line
                                    ></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.value="{ item }">
                            <v-edit-dialog
                                :return-value.sync="item.value"
                                @open="editedItem.valueAmount = item.valueAmount; editedItem.valueCurrency = item.valueCurrency"
                                @save="updateItem(item, 'value')"
                                @close="updateItem(item, 'value')"
                            >
                                {{ item.valueAmount }} {{ item.valueCurrency }}
                                <template v-slot:input>
                                    <v-text-field
                                        v-model.number="editedItem.valueAmount"
                                        label="Value Amount"
                                        type="number"
                                        single-line
                                    ></v-text-field>
                                    <v-select
                                        v-model="editedItem.valueCurrency"
                                        :items="currencies"
                                        label="Value Currency"
                                        single-line
                                    ></v-select>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.weight="{ item }">
                            <v-edit-dialog
                                :return-value.sync="item.weight"
                                @open="editedItem.weight=item.weight"
                                @save="updateItem(item, 'weight')"
                                @close="updateItem(item, 'weight')"
                            >
                                {{ item.weight }} pounds
                                <template v-slot:input>
                                    <v-text-field
                                        v-model.number="editedItem.weight"
                                        label="Weight"
                                        suffix="pounds"
                                        type="number"
                                        single-line
                                    ></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>
                        <template v-slot:item.memberQuantity="{ item }">
                            <span
                                v-on:click="expanded=[item]; editedItem.individuals = JSON.parse(JSON.stringify(item.individuals)); ownerInProgress=item.id"
                                class="pointer"
                            >
                                {{ item.memberQuantity }} 
                            </span>
                        </template>
                        <template v-slot:item.tags="props">
                            <span v-if="tagInProgress != props.item.id">
                                <v-chip 
                                    v-for="tag in props.item.tags" 
                                    :key="tag" 
                                    :color="getColor(tag)"
                                    v-on:click="filters.push(tag)"
                                    class="mr-2" 
                                    dark
                                >
                                    {{ tag }}
                                </v-chip>
                                <v-btn
                                    v-on:click="tagInProgress = props.item.id" 
                                    aria-label="edit tags"
                                    icon 
                                    x-small
                                >
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </span>
                            <v-row v-if="tagInProgress === props.item.id">
                                <v-col cols="10">
                                    <v-combobox
                                        v-model="props.item.tags"
                                        :items="allTags"
                                        chips
                                        multiple
                                        dense
                                    >
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                            <v-chip
                                                v-bind="attrs"
                                                :input-value="selected"
                                                :color="getColor(item)"
                                                close
                                                dark
                                                @click="select"
                                                @click:close="props.item.tags.splice(props.item.tags.indexOf(item), 1);"
                                            >
                                                {{ item }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn
                                        v-on:click="updateItem(props.item, 'tags')" 
                                        class="mt-1"
                                        color="green"
                                        aria-label="save tags"
                                        icon 
                                        x-small
                                    >
                                        <v-icon>
                                            mdi-check
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        v-on:click="tagInProgress = ''" 
                                        class="mt-1 mb-0"
                                        color="red"
                                        aria-label="close edit tags"
                                        icon 
                                        x-small
                                    >
                                        <v-icon>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <v-btn 
                                v-on:click="updateLoot(item)" 
                                color="primary" 
                                aria-label="edit item"
                                x-small
                                fab
                                outlined
                            >
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td
                                :colspan="headers.length" 
                                class="ml-0 pl-0 mr-0 pr-0 mt-0 pt-0 mb-0 pb-0"
                                align="left"
                            >
                                <v-card 
                                    class="mt-4 pt-0 mb-4 pb-0 ml-4 mr-4"
                                    flat
                                >
                                    <v-row>
                                        <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                                            <v-card 
                                                class="mt-0 pt-0 mb-0 pb-0"
                                                height="100%"
                                                outlined
                                            >
                                                <v-card-title>
                                                    <span class="text-subtitle-1">
                                                        Description
                                                    </span>
                                                    <v-spacer></v-spacer>
                                                    <v-btn 
                                                        v-if="descriptionInProgress!=item.id"
                                                        v-on:click="editedItem.description=item.description; descriptionInProgress=item.id"
                                                        color="primary"
                                                        aria-label="edit description"
                                                        icon
                                                        x-small
                                                    >
                                                        <v-icon>
                                                            mdi-pencil
                                                        </v-icon>
                                                    </v-btn>
                                                    <v-btn 
                                                        v-if="descriptionInProgress===item.id"
                                                        v-on:click="descriptionInProgress=''"
                                                        color="red"
                                                        aria-label="close edit description"
                                                        icon
                                                        x-small
                                                    >
                                                        <v-icon>
                                                            mdi-close
                                                        </v-icon>
                                                    </v-btn>
                                                </v-card-title>
                                                <v-card-text>
                                                    <span 
                                                        v-if="descriptionInProgress!=item.id"
                                                        class="ml-0" 
                                                        style="white-space: pre-wrap;"
                                                    >{{ item.description }}</span>
                                                    <span 
                                                        v-else
                                                        class="ml-0" 
                                                    >
                                                        <v-textarea
                                                            v-model="editedItem.description"
                                                            outlined
                                                            dense
                                                        ></v-textarea>
                                                        <v-btn 
                                                            v-on:click="updateItem(item, 'description')"
                                                            color="secondary"
                                                            depressed
                                                        >
                                                            Submit
                                                        </v-btn>
                                                    </span>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                                            <v-card 
                                                class="mt-0 pt-0 mb-0 pb-0"
                                                height="100%"
                                                outlined
                                            >
                                                <v-card-title>
                                                    <span class="text-subtitle-1">
                                                        Owners
                                                    </span>
                                                    <v-spacer></v-spacer>
                                                    <v-btn 
                                                        v-if="ownerInProgress!=item.id"
                                                        v-on:click="editedItem.individuals = JSON.parse(JSON.stringify(item.individuals)); ownerInProgress=item.id"
                                                        color="primary"
                                                        aria-label="edit owners"
                                                        icon
                                                        x-small
                                                    >
                                                        <v-icon>
                                                            mdi-pencil
                                                        </v-icon>
                                                    </v-btn>
                                                    <v-btn 
                                                        v-if="ownerInProgress===item.id"
                                                        v-on:click="editedItem.individuals = JSON.parse(JSON.stringify(item.individuals)); ownerInProgress=''"
                                                        color="red"
                                                        aria-label="close edit owners"
                                                        icon
                                                        x-small
                                                    >
                                                        <v-icon>
                                                            mdi-close
                                                        </v-icon>
                                                    </v-btn>
                                                </v-card-title>
                                                <v-card-text v-if="ownerInProgress!=item.id">
                                                    <v-chip 
                                                        v-for="i in item.individuals"
                                                        :key="i.owner"
                                                        class="mr-2 mb-2"
                                                        :color="member===i.owner ? '#66BB6A' : '#E0E0E0'"
                                                        label
                                                    >
                                                        {{ i.owner }} : {{ i.quantity }}
                                                    </v-chip>
                                                </v-card-text>
                                                <v-card-text v-else>
                                                    <v-row
                                                        v-for="(i, index) in editedItem.individuals"
                                                        :key="i.owner"
                                                        class="mt-0 pt-0 mb-0 pb-0"
                                                    >
                                                        <v-col 
                                                            cols="6"
                                                            class="mt-0 pt-0 mb-0 pb-0"
                                                        >
                                                            <v-select
                                                                v-model="i.owner"
                                                                :items="memberOptions"
                                                                outlined
                                                                dense
                                                            ></v-select>
                                                        </v-col>
                                                        <v-col 
                                                            cols="4"
                                                            class="mt-0 pt-0 mb-0 pb-0"
                                                        >
                                                            <v-text-field 
                                                                v-model.number="i.quantity"
                                                                outlined
                                                                dense
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col 
                                                            cols="2"
                                                            class="mt-0 pt-0 mb-0 pb-0"
                                                        >
                                                            <v-btn 
                                                                v-on:click="editedItem.individuals.splice(index, 1)"
                                                                v-if="editedItem.individuals.length > 1"
                                                                color="red"
                                                                :small="$vuetify.breakpoint.xs"
                                                                aria-label="delete owner"
                                                                outlined
                                                                icon
                                                            >
                                                                <v-icon>
                                                                    mdi-delete
                                                                </v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                    <v-btn 
                                                        v-on:click="editedItem.individuals.push({'owner' : member, 'quantity' : 1})"
                                                        color="green"
                                                        aria-label="add owner"
                                                        outlined
                                                        icon
                                                    >
                                                        <v-icon>
                                                            mdi-plus
                                                        </v-icon>
                                                    </v-btn>
                                                    <br><br>
                                                    <v-btn 
                                                        v-on:click="updateItem(item, 'owners')"
                                                        color="secondary"
                                                        depressed
                                                    >
                                                        Submit
                                                    </v-btn>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" xl="4" lg="4" md="4" sm="4">
                                            <v-card 
                                                class="mt-0 pt-0 mb-0 pb-0"
                                                height="100%"
                                                outlined
                                            >
                                                <v-card-title>
                                                    <span class="text-subtitle-1">
                                                        Edits
                                                    </span>
                                                </v-card-title>
                                                <v-card-text>
                                                    <span>
                                                        Created: {{ item.createdAt }}
                                                        <br>
                                                        Last Modified: {{ item.lastModifiedAt }}
                                                    </span>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </td>
                        </template>
                    </v-data-table>
                    <v-card-text class="text-left grey--text text--darken-2">
                        <span class="text-subtitle-1">
                            Total Weight: {{ lootWeight }} pounds
                        </span>
                        <br>
                        <span class="text-subtitle-1">
                            Total Value: {{ lootValue }} 
                        </span>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn 
                            v-on:click="updateLoot()" 
                            class="ml-2 mb-4"
                            color="secondary"
                            large 
                            depressed
                        >
                            Add Item
                            <v-icon class="ml-2">
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-btn>
                        <v-btn 
                            v-on:click="addRow()" 
                            class="ml-2 mb-4"
                            color="secondary" 

                            large 
                            depressed
                        >
                            Quick Add
                            <v-icon class="ml-2">
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog 
            v-model="dialog" 
            width="1200"
            persistent
        >
            <v-card>
                <AddItem 
                    v-on:closeDialog="dialog=false"
                    v-on:lootUpdated="lootUpdated" 
                    v-on:lootUpdateFailed="lootUpdateFailed" 
                    v-on:insufficientFunds="insufficientFunds"
                    :item="editItem"
                    :key="key"
                />
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbarSuccess"
            timeout="5000"
            color="green"
            dark
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on:click="snackbarSuccess = false"
                    text
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar
            v-model="snackbarError"
            timeout="5000"
            color="red"
            dark
        >
            {{ snackbarMessage }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on:click="snackbarError = false"
                    text
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
    import ColorHash from 'color-hash'
    import AddItem from './AddItem.vue';

    export default {
        name: 'LootList',
        components: {
            AddItem,
        },
        data() {
            return {
                search: "",
                filters: [],
                expanded: [],
                headers: [
                    { text: 'Item Name', value: 'name', width: '24%' },
                    { text: 'Value', value: 'value', filterable: false, width: '12%' },
                    { text: 'Weight', value: 'weight', filterable: false, width: '12%' },
                    { text: 'Quantity', value: 'memberQuantity', filterable: false, width: '12%' },
                    { text: 'Tags', value: 'tags', filter: this.tagsFilter, sortable: false, width: '35%' },
                    { text: '', value: 'edit', filterable: false, sortable: false, width: '5%' },
                ],
                currencies: [
                    { 'text' : 'Platinum', 'value' : 'platinum' },
                    { 'text' : 'Gold', 'value' : 'gold' },
                    { 'text' : 'Electrum', 'value' : 'electrum' },
                    { 'text' : 'Silver', 'value' : 'silver' },
                    { 'text' : 'Copper', 'value' : 'copper' },
                ],
                editedItem: {
                    'name' : '',
                    'description': '',
                    'valueAmount' : 0,
                    'valueCurrency' : '',
                    'weight' : 0,
                    'tags': [],
                    'individuals' : [],
                },
                tagInProgress: '',
                ownerInProgress: '',
                descriptionInProgress: '',
                editItem: null,
                dialog: false,
                key: 1,
                snackbarSuccess: false,
                snackbarError: false,
                snackbarMessage: '',
            };
        },
        props: {
            member: { type: String, default: '' },
        },
        computed: {
            // party loot
            loot: function() {
                // get the loot belonging to the member
                let loot = []
                if (this.member === '') {
                    loot = this.$store.getters.partyLoot;
                } else {
                    loot = this.$store.getters.partyLoot.filter((item) => {
                        return (
                            item.individuals.some((i) => { return i.owner === this.member; })
                        );
                    });
                }

                // calculate the quantity of each item for the given member
                loot.forEach((item) => {
                    item.memberQuantity = this.memberQuantity(item);
                })

                return loot;
            },
            
            // filter the loot based on the search and tag filters
            filteredLoot: function() {
                const filteredLoot = []
                this.loot.forEach((item) => {
                    let filter = true;
                    if (this.filters.length > 0) {
                        filter = item.tags.some((tag) => {
                            return this.filters.includes(tag);  
                        });
                    }

                    let search = true;
                    if (this.search != '') {
                        search = item.name.toLowerCase().includes(this.search.toLowerCase());
                    }

                    if (filter && search) {
                        filteredLoot.push(item);
                    }
                })

                return filteredLoot;
            },

            // total weight of the member's (filtered) loot
            lootWeight: function() {
                // get the total weight of the filtered loot belonging to the selected member
                return this.filteredLoot.reduce((lootWeight, item) => {
                    return (lootWeight + item.weight * item.memberQuantity)
                }, 0)
            },

            // total value of the members's (filtered) loot
            lootValue: function() {
                // for each item, increment the total loot value (in copper)
                const totalCopperValue = this.filteredLoot.reduce((copperValue, item) => {
                    return copperValue + (this.convertCurrency(item.valueAmount, item.valueCurrency) * item.memberQuantity);
                }, 0);

                // once all the items have been processed, convert the total copper value to gold, silver, and copper
                const goldValue = Math.floor(totalCopperValue / 100);
                const silverValue = Math.floor((totalCopperValue - (goldValue * 100)) / 10);
                const copperValue = totalCopperValue - (goldValue * 100) - (silverValue * 10);

                return goldValue.toString() + " gold, " + silverValue.toString() + " silver, " + copperValue.toString() + " copper";
            },

            // all tags belonging to the member's loot
            allTags: function() {
                return this.$store.getters.partyLootTags;
            },

            // memberOptions include all party members plus an option for the party (non-owned items)
            memberOptions: function() {
                return ['Party'].concat(this.$store.getters.partyMembers);
            },
        },
        methods: {
            // open the add / edit item dialog 
            updateLoot(item = null) {
                this.editItem = item;
                this.key += 1;
                this.dialog = true;
            },

            // reset variables and show success message on loot update
            lootUpdated() {
                this.dialog = false; 
                this.editItem = null;
                this.key += 1; 
                this.snackbarMessage = 'Party Loot Updated Successfully.'
                this.snackbarSuccess = true
            },

            // reset variables and show error message on loot update fail
            lootUpdateFailed() {
                this.snackbarMessage = 'Invalid Loot Details; Update Failed.'
                this.snackbarError = true;
            },

            // reset variables and show error message on loot add / edit fail
            insufficientFunds() {
                this.snackbarMessage = 'Insufficient Funds to Purchase Item.'
                this.snackbarError = true;
            },

            // get the quantity of a given item that the member owns
            memberQuantity(item) {
                return item.individuals.reduce((quantity, i) => {
                    if (i.owner === this.member || this.member === '') {
                        return quantity + i.quantity
                    } else {
                        return quantity
                    }
                }, 0);
            },

            // add an empty item row to the data table for editing
            addRow() {
                // create an empty item 
                const item = {
                    'id' : '',
                    'name' : '',
                    'description': '',
                    'valueAmount' : 0,
                    'valueCurrency' : 'gold',
                    'weight' : 0,
                    'individuals' : [ { 'owner' : 'Party', 'quantity' : 1} ], 
                    'tags' : [],
                    'createdAt' : Date.now(),
                    'lastModifiedAt' : Date.now()
                }

                // push details to state, which will update the database
                // display a sucess or failure message on completion
                this.$store.dispatch('addLoot', item)
                .then(() => { 
                    this.lootUpdated()
                }, () => {
                    this.lootUpdateFailed()
                });
            },

            // update features of the item in-table
            updateItem(item, key) {
                // reset edit variables
                this.tagInProgress = '';
                this.descriptionInProgress = '';
                this.ownerInProgress = '';

                // create a new item based on the old item
                const newItem = Object.assign({}, item)
                let itemChanged = false
                
                // change variables in the old item based on the key
                if (key === 'name') {
                    if (newItem.name !== this.editedItem.name) {
                        itemChanged = true
                        newItem.name = this.editedItem.name;
                    }
                }

                if (key === 'weight') {
                    if (newItem.weight !== this.editedItem.weight) {
                        itemChanged = true
                        newItem.weight = this.editedItem.weight;
                    }
                }

                if (key === 'value') {
                    if (newItem.valueAmount !== this.editedItem.valueAmount || newItem.valueCurrency !== this.editedItem.valueCurrency) {
                        itemChanged = true
                        newItem.valueAmount = this.editedItem.valueAmount;
                        newItem.valueCurrency = this.editedItem.valueCurrency;
                    }
                }

                if (key === 'description') {
                    itemChanged = true
                    newItem.description = this.editedItem.description;
                }

                if (key === 'owners') {
                    itemChanged = true
                    newItem.individuals = this.editedItem.individuals;

                    // remove empty owner rows and consolidate duplicates
                    for (var i1 = 0; i1 < newItem.individuals.length; i1++) {
                    if (newItem.individuals[i1].owner === '' || newItem.individuals[i1].quantity === 0) {
                            newItem.individuals.splice(i1, 1);
                        } else {
                            for (var i2 = i1 + 1; i2 < newItem.individuals.length; i2++) {
                                if (newItem.individuals[i1].owner === newItem.individuals[i2].owner) {
                                    newItem.individuals[i1].quantity += newItem.individuals[i2].quantity;
                                    newItem.individuals.splice(i2, 1);
                                }
                            }
                        }
                    }
                }

                if (key === 'tags') {
                    itemChanged = true
                    this.editedItem.tags = item.tags
                    newItem.tags = this.editedItem.tags;
                }

                // only update the item if it has actually changed
                if (itemChanged) {
                    // update created and last modified at timestamps
                    newItem.createdAt = new Date(newItem.createdAt).getTime();
                    newItem.lastModifiedAt = Date.now();

                    // push details to state, which will update the database
                    // display a sucess or failure message on completion
                    this.$store.dispatch('editLoot', newItem)
                    .then(() => { 
                        this.lootUpdated()
                    }, () => {
                        this.lootUpdateFailed()
                    });
                }
            },

            // add tag filters
            tagsFilter(value) {
                if (this.filters.length === 0) { 
                    return true; 
                } else {
                    return (value.some((item) => { return this.filters.includes(item) }));
                }
            },

            // custom sort function for data table
            customSort(items, index, isDesc) {
                items.sort((a, b) => {
                    if (index[0] === "value") {
                        // convert currencies to copper before comparing
                        const aCopper = this.convertCurrency(a.valueAmount, a.valueCurrency);
                        const bCopper = this.convertCurrency(b.valueAmount, b.valueCurrency);

                        if (!isDesc[0]) {
                            return this.compare(aCopper, bCopper);
                        } else {
                            return this.compare(bCopper, aCopper);
                        }
                    } else {
                        if (!isDesc[0]) {
                            return this.compare(a[index[0]], b[index[0]]);
                        } else {
                            return this.compare(b[index[0]], a[index[0]]);
                        }
                    }
                });
                return items;
            },

            // compare two numbers or strings
            compare(a, b) {
                if (a > b) { return 1; }
                else if (a < b) { return -1; }
                else if (a === b) { return 0; }
            },

            // convert value amount + currency to copper
            convertCurrency(valueAmount, valueCurrency) {
                let copperAmount = 0

                if (valueCurrency === 'platinum') { 
                    copperAmount = valueAmount * 1000; 
                } else if (valueCurrency === 'gold') { 
                    copperAmount = valueAmount * 100; 
                } else if (valueCurrency === 'electrum') { 
                    copperAmount = valueAmount * 50; 
                } else if (valueCurrency === 'silver') { 
                    copperAmount = valueAmount * 10; 
                } else if (valueCurrency === 'copper') { 
                    copperAmount = valueAmount; 
                } 

                return copperAmount;
            },

            // get color of tag
            getColor(text) {
                const colorHash = new ColorHash();
                return colorHash.hex(text);
            }
        },
    }
</script>
