var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"6"}},[_c('v-card-title',[(!_vm.edit)?_c('span',{staticClass:"text-h6"},[_vm._v(" Add Item ")]):_vm._e(),(_vm.edit)?_c('span',{staticClass:"text-h6"},[_vm._v(" Edit Item ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon')],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Item name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4 mr-4",attrs:{"label":"Item Name","error-messages":errors,"outlined":"","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-0 mb-0 pt-0 pb-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"ml-4 mr-4",attrs:{"label":"Description","rows":"3","error-messages":errors,"outlined":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-0 mb-0 pt-0 pb-0",attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Weight","rules":"double:1,dot|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4 mr-4",attrs:{"label":"Weight","suffix":"pounds","error-messages":errors,"outlined":""},model:{value:(_vm.weight),callback:function ($$v) {_vm.weight=_vm._n($$v)},expression:"weight"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-0 mb-0 pt-0 pb-0",attrs:{"cols":"0","xl":"6","lg":"6","md":"6","sm":"6"}})],1),_c('v-row',[_c('v-col',{staticClass:"mt-0 mb-0 pt-0 pb-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Value amount","rules":"numeric|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Value","error-messages":errors,"outlined":""},model:{value:(_vm.valueAmount),callback:function ($$v) {_vm.valueAmount=_vm._n($$v)},expression:"valueAmount"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-0 mb-0 pt-0 pb-0",attrs:{"cols":"6"}},[_c('v-select',{staticClass:"mr-4",attrs:{"items":_vm.currencies,"label":"Currency","outlined":""},model:{value:(_vm.valueCurrency),callback:function ($$v) {_vm.valueCurrency=$$v},expression:"valueCurrency"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-0 mb-0 pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"ml-4 mt-0 pt-0",scopedSlots:_vm._u([{key:"label",fn:function(){return [(!_vm.edit)?_c('span',[_vm._v(" Purchase Item with Party Coins ")]):_vm._e(),(_vm.edit)?_c('span',[_vm._v(" Purchase Additional Items with Party Coins ")]):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4"},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle ")])]}}],null,true)},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(" If this box is checked, the amount below will be deducted from the party coins. ")])])]},proxy:true}],null,true),model:{value:(_vm.buyItem),callback:function ($$v) {_vm.buyItem=$$v},expression:"buyItem"}})],1)],1),(_vm.buyItem)?_c('v-row',[_c('v-col',{staticClass:"mt-0 mb-0 pt-0 pb-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Cost amount","rules":"numeric|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4",attrs:{"label":"Total Cost","error-messages":errors,"outlined":""},model:{value:(_vm.costAmount),callback:function ($$v) {_vm.costAmount=_vm._n($$v)},expression:"costAmount"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-0 mb-0 pt-0 pb-0",attrs:{"cols":"6"}},[_c('v-select',{staticClass:"mr-4",attrs:{"items":_vm.currencies,"label":"Currency","outlined":""},model:{value:(_vm.costCurrency),callback:function ($$v) {_vm.costCurrency=$$v},expression:"costCurrency"}})],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","xl":"6","lg":"6","md":"6","sm":"6"}},[_c('v-card-title',[_c('span',{staticClass:"text-h6"},[_vm._v(" Item Owners ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","outlined":"","icon":""},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_vm._l((_vm.individuals),function(i,index){return _c('v-row',{key:i.id},[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"4.5"}},[_c('v-select',{staticClass:"ml-4",attrs:{"items":_vm.memberOptions,"label":"Owner","outlined":""},model:{value:(i.owner),callback:function ($$v) {_vm.$set(i, "owner", $$v)},expression:"i.owner"}})],1),_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"4.5"}},[_c('validation-provider',{attrs:{"name":"Quantity","rules":"numeric|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Quantity","error-messages":errors,"outlined":""},model:{value:(i.quantity),callback:function ($$v) {_vm.$set(i, "quantity", _vm._n($$v))},expression:"i.quantity"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"3"}},[(_vm.individuals.length > 1)?_c('v-btn',{staticClass:"mt-1",attrs:{"color":"red","aria-label":"delete owner","outlined":"","large":"","icon":""},on:{"click":function($event){return _vm.individuals.splice(index, 1)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()],1)],1)}),_c('v-row',[_c('v-col',{staticClass:"mt-0 mb-0 pt-0 pb-0 ml-4",attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"green","aria-label":"add owner","outlined":"","icon":""},on:{"click":function($event){return _vm.individuals.push({'owner' : 'Party', 'quantity' : 1})}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1),_c('v-col',{staticClass:"mt-0 mb-0 pt-0 pb-0",attrs:{"cols":"4"}})],1),_c('v-card-title',[_c('span',{staticClass:"text-h6 mt-4"},[_vm._v(" Tags ")])]),_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"12"}},[_c('v-combobox',{staticClass:"ml-4 mr-4",attrs:{"items":_vm.allTags,"label":"Tags","chips":"","multiple":"","solo":"","flat":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"color":_vm.getColor(item),"close":"","dark":""},on:{"click":select,"click:close":function($event){_vm.tags.splice(_vm.tags.indexOf(item), 1)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}],null,true),model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1)],1)],2)],1)],1),_c('v-btn',{staticClass:"ml-4 mr-4 mt-4 mb-4",attrs:{"color":"primary","disabled":invalid,"x-large":"","depressed":""},on:{"click":_vm.addItem}},[_vm._v(" Submit ")]),(_vm.edit)?_c('v-btn',{attrs:{"color":"red","x-large":"","dark":""},on:{"click":_vm.deleteLoot}},[_vm._v(" Delete ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }